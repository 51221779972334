import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Workspace from './WorkSpace';
import NoteEditor from './NoteEditor';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Workspace />} />
        <Route path="/notes/:noteId" element={<NoteEditor />} />
      </Routes>
    </Router>
  );
}

export default App;
