import React, { useState, useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import Color from '@tiptap/extension-color';
import StarterKit from '@tiptap/starter-kit';
import { useLocation} from 'react-router-dom';
import TextStyle from '@tiptap/extension-text-style';
import ListItem from '@tiptap/extension-list-item'
import TipTapLink from '@tiptap/extension-link';
import axios from './axiosConfig';
import './NoteEditor.css'
import { useParams } from 'react-router-dom'; // Import useParams to get noteId from the URL


import { Trash2, FileText, Settings, Share, Send, X, Bold, 
  Italic, List, ListOrdered, Link, Heading1, Heading2, 
  Heading3, Search, Copy, Redo, Undo, Circle } from 'lucide-react';


const NoteEditor = ({onClose}) => {
  const location = useLocation();
  const { checkedDocuments } = location.state || { checkedDocuments: [] };
  const [message, setMessage] = useState('');
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]); // Store selected documents
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNoteSidebarOpen, setIsNoteSidebarOpen] = useState(true);
  const [noteTitle, setNoteTitle] = useState("");
  const [activeTab, setActiveTab] = useState("edit");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // State for controlling the upload modal visibility
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatSelectedDocuments, setChatSelectedDocuments] = useState([]); // For documents to send in chat
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);
  const { noteId } = useParams(); // Get the noteId from the URL
  const [note, setNote] = useState(null); // Initialize the note state
  const [loading, setLoading] = useState(true); // Loading state for fetching data


  useEffect(() => {
    // Fetch the note data by noteId
    const fetchNote = async () => {
      try {
        const response = await axios.get(`/api/notes/${noteId}`);
        setNote(response.data); // Set the fetched note data
        setLoading(false); // Turn off the loading state
      } catch (error) {
        console.error('Error fetching the note:', error);
        setLoading(false); // Turn off the loading state even on error
      }
    };
    fetchNote();
  }, [noteId]); // Re-run the effect whenever noteId changes
  

  // Update the title when the note prop changes
  useEffect(() => {
    if (note && note.title) {
      setNoteTitle(note.title);
    }
  }, [note]);

  // Check NoteID if exist
  useEffect(() => {
    if (!note || !note.id) {
      console.warn('No note ID available for update.');
    } else {
      console.log('Editing note with ID:', note.id);
    }
  }, [note]);

  useEffect(() => {
    if (note) {
        // Ensure the selected documents are loaded when the note is opened
        setSelectedDocuments(note.selected_documents || []);
        console.log('Selected documents loaded:', note.selected_documents);
    }
}, [note]);

  // check chat history
  useEffect(() => {
    if (note && note.chat_history) {
        setChatHistory(note.chat_history);
    }
}, [note]);


  // editor extension and update
  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      TipTapLink.configure({ openOnClick: true }),
      StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },
        orderedList: { keepMarks: true, keepAttributes: false },
        heading: { levels: [1, 2, 3] },
      }),
    ],
    content: '',
    onUpdate: async ({ editor }) => {
      if (note?.id) {
        try {
          const updatedContent = editor.getHTML();  // Capture updated HTML content
          console.log('Updated content:', updatedContent);  // Log content to ensure it's correct
    
          // Patch request to update note content
          const response = await axios.patch(`/api/notes/${note.id}`, {
            content: updatedContent,  // Send updated content
            last_edited: new Date().toISOString(),
          });
          console.log('Note updated successfully:', response.data);
        } catch (error) {
          console.error('Error updating note content:', error.response?.data || error.message);
        }
      } else {
        console.error('No note ID available for update.');
      }
    },    
    editorProps: {
      attributes: {
        class: 'focus:outline-none border-none',
      },
    },
    placeholder: 'Start typing your notes here...',
  });

  // Once the note is fetched, update the editor content
  useEffect(() => {
    if (note && editor) {
      editor.commands.setContent(note.content || '');  // Set editor content with fetched note content
      setNoteTitle(note.title);
      setSelectedDocuments(note.selected_documents || []);
      setChatHistory(note.chat_history || []);
    }
  }, [note, editor]);
  

  if (loading) {
    return <div>Loading...</div>; // Display loading indicator while fetching the note
  }

  if (!note) {
    return <div>Note not found</div>; // Display message if the note is not found
  }
  /// Fucntion for formating text
  
  function cleanText(text) {
    return text.replace(/\n/g, '<br>').replace(/\xa0/g, '&nbsp;');
  }

  function formatMessage(text) {
    // Convert markdown-like bold syntax (**text**) to <strong>text</strong>
    const formattedText = text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/\n\n/g, '<br />'); // Convert double newlines to <br />
  
    return formattedText;
  }


  const handleSendMessage = async () => {
    if (message.trim()) {
      const userMessage = { sender: 'user', text: message };
      const updatedChatHistory = [...chatHistory, userMessage];
      setChatHistory(updatedChatHistory);
      setMessage('');
      setIsLoading(true);

      try {
        const response = await axios.post('api/chat_with_documents', {
          query: message,
          selected_documents: chatSelectedDocuments, // Use only the selected documents for chat
        });

        const botMessage = {
          sender: 'bot',
          text: response.data.answer,
          documents_used: response.data.documents_used,
        };
        const newChatHistory = [...updatedChatHistory, botMessage];
        setChatHistory(newChatHistory);

        // 4. Update Chat History in Database if note.id exists
        if (note.id) {
          await axios.patch(`/api/notes/${note.id}`, {
            last_edited: new Date().toISOString(), // Update the last edited time
            chat_history: newChatHistory, // Update with the new chat history
          });
          console.log('Chat history updated successfully.');
        } else {
          console.error('No note ID available for chat history update.');
        }
      } catch (error) {
        console.error('Error sending message:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // allow press "Enter" to send message
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };
  
  const handleUploadClick = () => {
    setIsUploadModalOpen(true); // Open the modal
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false); // Close the modal
  };

  
  // Handle the selection of documents
  const handleSelectDocument = (document) => {
    setSelectedDocuments((prevSelected) => {
      if (prevSelected.includes(document)) {
        return prevSelected.filter((d) => d !== document);
      } else {
        return [...prevSelected, document];
      }
    });
  };

  const handleDocumentClick = (doc) => {
    setSelectedDoc(doc); // Set the selected document
    setIsSidebarOpen(true); // Open the sidebar with document details
  };

  // Handle the click on the trash icon to show confirmation
  const handleDeleteIconClick = (doc) => {
    setDocToDelete(doc);
    setIsConfirmingDelete(true);
  };

  // Handle delete confirmation
  const confirmDeleteDocument = async (doc) => {
    try {
      const updatedDocuments = selectedDocuments.filter(d => d !== doc);
      setSelectedDocuments(updatedDocuments);
  
      // PATCH request to update the note with the updated selected documents
      await axios.patch(`/api/notes/${note.id}`, {
        selected_documents: updatedDocuments,
        last_edited: new Date().toISOString(), // Keep last edited timestamp updated
      });
  
      console.log('Document deleted and note updated.');
    } catch (error) {
      console.error('Error deleting document:', error);
    } finally {
      setIsConfirmingDelete(false); // Close confirmation modal
    }
  };
  

// Cancel the deletion process
const cancelDelete = () => {
  setIsConfirmingDelete(false);
  setDocToDelete(null);
};

  const handleChatWithSelected = async () => {
    if (selectedDocuments.length > 0) {
      const combinedDocuments = [...additionalDocuments, ...selectedDocuments];
      const uniqueDocuments = Array.from(
        new Map(
          combinedDocuments.map((doc) => [
            doc.id || doc.pdf_path || doc.m_pdf_path,
            doc,
          ])
        ).values()
      );
      setAdditionalDocuments(uniqueDocuments);
      setIsUploadModalOpen(false); // Close the upload modal
  
      // Update the selected documents in the note
      if (note.id) {
        try {
          await axios.patch(`/api/notes/${note.id}`, {
            last_edited: new Date().toISOString(),
            selected_documents: uniqueDocuments,
          });
          console.log('Selected documents updated successfully.');
        } catch (error) {
          console.error('Error updating selected documents:', error);
        }
      } else {
        console.error('No note ID available for updating selected documents.');
      }
    }
  };
  
  // handle step 2 sub-select document for chat-with-documents
  const handleDocumentCheckbox = (document) => {
    setChatSelectedDocuments((prevSelected) => {
      if (prevSelected.includes(document)) {
        // If already selected, remove it from the selection
        return prevSelected.filter((doc) => doc !== document);
      } else {
        // If not selected, add it to the selection
        return [...prevSelected, document];
      }
    });
  };
  
  

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      setIsSearchLoading(true);

      fetch('/api/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: searchQuery }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.results && Array.isArray(data.results) && data.m_results && Array.isArray(data.m_results)) {
            const dekaResultsWithCheckbox = data.results.map(result => ({
              ...result,
              isChecked: false,
            }));
            const martharResultsWithCheckbox = data.m_results.map(result => ({
              ...result,
              isChecked: false,
            }));
        
            // Store both types of results in the state
            setSearchResults({ 
              dekaResults: dekaResultsWithCheckbox, 
              martharResults: martharResultsWithCheckbox 
            });
            setActiveTab('all'); // Automatically select "All Documents" tab when search results are loaded
          } else {
            console.error('Expected arrays of results but got:', data);
            setSearchResults({ dekaResults: [], martharResults: [] });
          }
          setIsSearchLoading(false);
        })
        .catch(error => {
          console.error('Error fetching search results:', error);
          setIsSearchLoading(false);
        });        
    }
  };
  



  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleNoteSidebar = () => {
    setIsNoteSidebarOpen(!isNoteSidebarOpen);
  }; 

  // Formating Editor for Note Taking
  const applyFormatting = (formatType) => {
    if (!editor) return;

    switch (formatType) {
      case 'order':
        editor.chain().focus().toggleOrderedList().run();
        break;
      case 'h1':
        editor.chain().focus().toggleHeading({ level: 1 }).run();
        break;
      case 'h2':
        editor.chain().focus().toggleHeading({ level: 2 }).run();
        break;
      case 'h3':
        editor.chain().focus().toggleHeading({ level: 3 }).run();
        break;
      case 'purple':
        editor.chain().focus().setColor('#800080').run();
        break;
      case 'Redo':
        editor.chain().focus().redo().run()
        break;
      case 'Undo':
        editor.chain().focus().undo().run()
        break;
      case 'bold':
        editor.chain().focus().toggleBold().run();
        break;
      case 'italic':
        editor.chain().focus().toggleItalic().run();
        break;
      case 'list':
        editor.chain().focus().toggleBulletList().run();
        break;
      case 'link':
        const url = prompt('Enter the URL:', 'https://');
        if (url) {
          editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
        }
        break;
      default:
        break;
    }
  };
  

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-72 bg-white border-r border-gray-200 p-4">
        <h1 className="text-2xl font-bold mb-4 text-center">KHORN</h1>
        <h2 className="text-lg font-semibold mb-4">Selected Documents</h2>
        {/* Render List of Selected Documents */}
        <ul className="space-y-2">
          {selectedDocuments.map((doc) => (
            <li
              key={doc.pdf_path || doc.m_pdf_path}
              className="flex items-center space-x-2 cursor-pointer hover:bg-gray-100 p-2 rounded-lg"
              onClick={() => handleDocumentClick(doc)}
            >
              <div onClick={(e) => e.stopPropagation()} className="pr-2">
                <input
                  type="checkbox"
                  checked={chatSelectedDocuments.includes(doc)}
                  onChange={() => handleDocumentCheckbox(doc)}
                />
              </div>
              {/* <FileText size={16} className="document-icon" /> */}
              <div className="flex-1 cursor-pointer">
                <span
                  className="selected-documents-list"
                  title={doc.deka_name || doc.marthar || doc.info || 'No Title Available'} // Tooltip
                >
                  {doc.deka_name || doc.marthar || doc.info || 'No Title Available'}
                </span>
                <p className="selected-documents-subtext">
                  {doc.deka_name ? 'คำพิพากษาฎีกา' : doc.marthar ? 'มาตรากฎหมาย' : 'Unknown'}
                </p>
              </div>
              {/* Trash Icon for Removing Document */}
              <Trash2
                className="cursor-pointer text-gray-500 hover:text-red-700 transform transition-transform duration-200 hover:scale-125"
                size={18}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering document click
                  handleDeleteIconClick(doc); // Show delete confirmation
                }}
              />
            </li>
          ))}
        </ul>

        <div className="mt-4">
          <button
            className="w-full bg-white-950 text-black p-2 rounded-3xl hover:bg-slate-950 hover:text-white transition-all duration-300 ease-in-out border border-black"
            onClick={handleUploadClick}
          >
            Add More Documents
          </button>
        </div>
        {/* Delete Confirmation Popup */}
        {isConfirmingDelete && docToDelete && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded shadow-lg">
              <h3 className="text-xl font-bold mb-4">
                Deleting "{docToDelete.deka_name || docToDelete.marthar || 'this document'}"?
              </h3>
              <div className="flex justify-end">
                <button 
                  className="bg-red-500 text-white p-2 rounded mr-2"
                  onClick={() => confirmDeleteDocument(docToDelete)}
                >
                  Yes
                </button>
                <button 
                  className="bg-gray-300 p-2 rounded"
                  onClick={cancelDelete}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Main Chat Area */}
      <div className={`flex-1 flex flex-col transition-all duration-300 ${isSidebarOpen ? 'ml-32' : ''} ${isNoteSidebarOpen ? 'mr-72' : ''}`}>
        <div className="bg-white border-b border-gray-200 p-4 flex justify-between items-center">
          <h1 className="text-xl font-bold">Chat with Documents</h1>
          <div className="flex space-x-4">
            <Settings size={20} className="cursor-pointer text-gray-500 hover:text-gray-700" />
            <Share size={20} className="cursor-pointer text-gray-500 hover:text-gray-700" />
            <button
              className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition"
              onClick={toggleNoteSidebar}
            >
              {isNoteSidebarOpen ? "Close Notes" : "Open Notes"}
            </button>
          </div>
        </div>

        {/* Chat Messages Section */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50">


          {/* Render the chat history */}
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`p-3 rounded-lg max-w-3/4 shadow-md ${
                chat.sender === 'user' ? 'bg-blue-100 ml-auto text-right'  : 'bg-gray-100'
              }`}
            >
              <p 
                className={chat.sender === 'user' ? 'text-blue-800' : 'text-gray-800'}
                dangerouslySetInnerHTML={{ __html: formatMessage(chat.text) }}
              ></p>
              {/* Copy Icon */}
              <button 
                className="ml-2 text-gray-400 hover:text-gray-600"
                onClick={() => navigator.clipboard.writeText(chat.text)}
                title="Copy to clipboard"
              >
                <Copy size={16} />
              </button>
              {chat.documents_used && chat.sender === 'bot' && (
              <div className="mt-2 flex flex-wrap gap-2"> <p className="p-2 rounded">เอกสารอ้างอิงทั้งหมด</p>
                {chat.documents_used.map((doc, index) => (
                <div
                  key={index}
                  className={`p-2 rounded shadow-sm ${
                    doc.includes('deka_name') ? 'bg-blue-200 text-blue-900' : 'bg-gray-200 text-black'
                  }`}
                >
                  {doc}
                </div>
              ))}
              </div>
            )}
              
          </div>
          ))}
          {/* Loading indicator */}
          {isLoading && (
            <div className="p-3 text-center">
              <div className="skeleton h-8 bg-gray-300 rounded w-full max-w-3/4 mx-auto"></div>
            </div>
          )}
        </div>

        {/* Input Field for User Messages */}
        <div className="bg-white border-t border-gray-200 p-4 flex items-center">
          <input
            type="text"
            placeholder="Type your message here..."
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown} // Handle Enter key submission
          />
          <button
            className={`ml-2 flex items-center p-2 rounded-lg transition ${
              message.trim() ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-300 text-gray-500'
            }`}
            onClick={handleSendMessage}
            disabled={!message.trim()}
          >
            <Send className="w-5 h-5" />
          </button>
        </div>
      </div>


      {isUploadModalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
        <div className="bg-white w-11/12 max-w-4xl p-8 rounded-lg h-3/4 overflow-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Upload Document</h2>
            <button onClick={handleCloseUploadModal}>
              <X size={20} />
            </button>
          </div>

      {/* Search Input */}
      <form onSubmit={handleSearch} className="w-full max-w-6xl mb-4">
        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Enter your legal query..."
            className="w-full p-4 pr-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button type="submit" className="absolute right-4 top-1/2 transform -translate-y-1/2">
            <Search className="text-gray-400" />
          </button>
        </div>
      </form>

      {/* Conditionally show tabs only if search results are available */}
      {searchResults.dekaResults?.length > 0 || searchResults.martharResults?.length > 0 ? (
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4">
          <ul className="flex border-b">
            <li
              className={`p-2 cursor-pointer ${activeTab === 'all' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('all')}
            >
              เอกสารทั้งหมด
            </li>
            <li
              className={`p-2 cursor-pointer ${activeTab === 'deka' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('deka')}
            >
              คำพิพากษาฎีกา
            </li>
            <li
              className={`p-2 cursor-pointer ${activeTab === 'marthar' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('marthar')}
            >
              มาตรากฎหมาย
            </li>
          </ul>
          </div>
          
          {/* Button for proceeding to chat with selected documents */}
          {selectedDocuments.length > 0 && (
            <div className="flex justify-end">
              <button
                className="bg-blue-500 text-white p-2 rounded-lg"
                onClick={handleChatWithSelected}
              >
                Chat with Selected ({selectedDocuments.length})
              </button>
            </div>
          )}
        </div>
      ) : null}
      {isSearchLoading ? (
        <div className="w-full max-w-6xl p-4 bg-gray-200 rounded-lg mb-4">
          <div className="skeleton-block skeleton h-8 bg-gray-300"></div>
          <div className="skeleton-block skeleton h-8 bg-gray-300"></div>
          <div className="skeleton-block skeleton h-8 bg-gray-300"></div>
        </div>
      ) : (
        <ul className="space-y-2">
          {/* Conditionally render the list based on the active tab */}
          {activeTab === 'all' && (
            [...(searchResults.martharResults || []), ...(searchResults.dekaResults || [])].map((result) => (
              <li
                key={result.pdf_path || result.m_pdf_path}
                className="p-2 border rounded hover:bg-gray-100 cursor-pointer relative"
                onClick={() => handleSelectDocument(result)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="font-bold">{result.deka_name || result.marthar || 'No Title Available'}<span
                    className={`p-1 rounded text-xs ${
                      selectedDocuments.includes(result)
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-300 text-gray-700'
                    }`}
                  >
                    {selectedDocuments.includes(result) ? 'Selected' : 'Not Selected'}
                  </span></h4>
                    <p className="text-sm text-gray-600">{result.short_deka || result.info || 'No Description Available'}</p>
                  </div>
                </div>
              </li>
            ))
          )}
          {activeTab === 'deka' && (
            searchResults.dekaResults?.map((result) => (
              <li
                key={result.pdf_path}
                className="p-2 border rounded hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSelectDocument(result)}
              >
                 <div className="flex justify-between items-start">
                  <div>
                    <h4 className="font-bold">{result.deka_name || result.marthar || 'No Title Available'}<span
                    className={`p-1 rounded text-xs ${
                      selectedDocuments.includes(result)
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-300 text-gray-700'
                    }`}
                  >
                    {selectedDocuments.includes(result) ? 'Selected' : 'Not Selected'}
                  </span></h4>
                    <p className="text-sm text-gray-600">{result.short_deka || result.info || 'No Description Available'}</p>
                  </div>
                </div>
              </li>
            ))
          )}
          {activeTab === 'marthar' && (
            searchResults.martharResults?.map((result) => (
              <li
                key={result.m_pdf_path}
                className="p-2 border rounded hover:bg-gray-100 cursor-pointer"
                onClick={() => handleSelectDocument(result)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="font-bold">{result.marthar || 'No Title Available'}<span
                    className={`p-1 rounded text-xs ${
                      selectedDocuments.includes(result)
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-300 text-gray-700'
                    }`}
                  >
                    {selectedDocuments.includes(result) ? 'Selected' : 'Not Selected'}
                  </span></h4>
                    <p className="text-sm text-gray-600">{result.info || 'No Description Available'}</p>
                  </div>
                </div>
                <p className="text-sm text-gray-600">{result.info || 'No Description Available'}</p>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  </div>
)}



      {/* Sliding Sidebar for Document Details */}
      <div
        className={`fixed left-0 top-0 h-full w-[40rem] bg-white border-r border-gray-200 p-4 shadow-lg transition-transform duration-300 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Document Details</h2>
          <button onClick={handleCloseSidebar}>
            <X size={20} />
          </button>
        </div>

        {selectedDoc ? (
        <div>
        {selectedDoc.pdf_path ? ( // This means it's a Deka document
        <div>
          <h2 className="text-xl font-semibold mb-2">{selectedDoc.deka_name || 'No Title Available'}</h2>
            <p><strong>ปี:</strong> {selectedDoc.year || 'N/A'}</p>
            <p><strong>กฎหมายที่เกี่ยวข้อง:</strong> {selectedDoc.related_law || 'N/A'}</p>
            <p><strong>สรุป:</strong> {selectedDoc.case_summary || 'N/A'}</p>
        {/* Scrollable content section */}
          <p className="mt-4"> <strong>ย่อสั้น:</strong></p>
          <div className="mt-4 overflow-y-auto max-h-48 p-2 border rounded bg-white-900">
            <p> {selectedDoc.short_deka || 'Full content not available.'}</p>
          </div>
          <p className="mt-4"> <strong>ย่อยาว:</strong></p>
          <div className="mt-4 overflow-y-auto max-h-96 p-2 border rounded bg-white-900">
            <p dangerouslySetInnerHTML={{ __html: cleanText(selectedDoc.full_deka) || 'Full content not available.'}}></p>
          </div>
        </div>
        ) : selectedDoc.m_pdf_path ? ( // This means it's a Marthar document
          <div>
            {/* Use dangerouslySetInnerHTML to display cleaned HTML */}
            <h2 className="text-xl font-semibold mb-2">{selectedDoc.marthar || 'No Title Available'}</h2>
              <strong>รายละเอียด:</strong> 
              <p dangerouslySetInnerHTML={{ __html: cleanText(selectedDoc.info) || 'No Title Available'}}></p>
          </div>
          ) : (
          <p>No document details available.</p>
          )}
        </div>
        ) : (
        <p>Select a document to view its details.</p>
        )}
      </div>
      



      {/* Note-Taking Sidebar */}
      <div className={`fixed right-0 top-0 h-full w-[18rem] bg-white border-l border-gray-200 p-4 transition-transform duration-300 ${
        isNoteSidebarOpen ? 'translate-x-0' : 'translate-x-full'
      }`}>
        <h2 className="text-lg font-semibold mb-4">Note Taking</h2>
        <input
          type="text"
          value={noteTitle}
          onChange={(e) => setNoteTitle(e.target.value)}
          onBlur={async () => {
            if (note.id) {
              try {
                await axios.patch(`/api/notes/${note.id}`, {
                  title: noteTitle.trim(), // Ensure the title is sent properly
                  // content: note.content || '', // Include content to meet the expected structure
                  last_edited: new Date().toISOString(), // Update the last edited time
                  // selected_documents: note.selected_documents || [],
                  // chat_history: note.chat_history || [],
                });
                console.log('Note title updated successfully.');
              } catch (error) {
                console.error('Error updating note title:', error);
              }
            } else {
              console.error('No note ID available for title update.');
            }
          }}
          placeholder="Note Title"
          className="w-full p-2 rounded-lg mb-2"
        />
        <div className="flex items-center space-x-2 mb-2 flex-nowrap overflow-x-auto min-w-full">
          <button className="p-2 border rounded" onClick={() => applyFormatting('bold')}>
            <Bold size={16} />
          </button>
          <button className="p-2 border rounded" onClick={() => applyFormatting('italic')}>
            <Italic size={16} />
          </button>
          <button className="p-2 border rounded" onClick={() => applyFormatting('list')}>
            <List size={16} />
          </button>
          <button className="p-2 border rounded" onClick={() => applyFormatting('order')}>
            <ListOrdered size={16} />
          </button>
          <button className="p-2 border rounded" onClick={() => applyFormatting('link')}>
            <Link size={16} />
          </button>
          <button className="p-2 border rounded" onClick={() => applyFormatting('h1')}>
            <Heading1 size={16}/>
          </button>
        </div>
        <div className="flex items-center space-x-2 mb-2 flex-nowrap overflow-x-auto min-w-full">
          <button className="p-2 border rounded" onClick={() => applyFormatting('h2')}>
            <Heading2 size={16}/>
          </button>
          <button className="p-2 border rounded" onClick={() => applyFormatting('h3')}>
            <Heading3 size={16}/>
          </button>
          <button className="p-2 border rounded" onClick={() => applyFormatting('Undo')}>
            <Undo size={16} />
          </button>
          <button className="p-2 border rounded" onClick={() => applyFormatting('Redo')}>
            <Redo size={16} />
          </button>
          <button className="p-2 border rounded" onClick={() => applyFormatting('purple')}>
            <Circle size={16} color="purple"/>
          </button>
        </div>
        <div
  className="border border-gray-300 rounded-lg mb-4 p-4 min-h-[450px] max-h-[500px] overflow-y-auto"
          onClick={() => editor?.commands.focus()} // Focus the editor when the area is clicked
        >
          <EditorContent editor={editor} />
        </div>

        <button className="bg-blue-500 text-white p-2 rounded-lg w-full mt-2">
          Save Note
        </button>
      </div>


    {/* end div */}
    </div>
  );
};

export default NoteEditor;